<template>

<app-page>

	<app-page-head title="Analytics">

	</app-page-head>

	<app-page-content :is-padded="true" class="share">

		abc

	</app-page-content>

</app-page>

</template>

<script>

export default {
	
	data: function() {

		return {
		}

	}

}

</script>

<style scoped>

</style>
